import React, { useState, useEffect, useRef } from "react"
import { Box, Button, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
// import {useTimeoutReset} from "../components/utils/hooks/useTimeoutReset"

const TestPage = ({ classes }) => {
  const [counting, setCounting] = useState(false)
  const [show, setShow] = useState(false)
  const delay = 10
  const [rand, setRand] = useState(null)
  const timer = useRef(null)

  const handleMe = () => {
    setShow(true)
    setCounting(false)
  }

  useEffect(() => {
    setCounting(true)
    setRand(Math.random)
  }, [])

  useEffect(() => {
    if (counting) {
      timer.current = setTimeout(function () {
        handleMe()
      }, delay * 1000)
      return () => {
        clearTimeout(timer.current)
      }
    }
  }, [rand])

  const handleReset = () => {
    clearTimeout(timer.current)
    setCounting(true)
    setShow(false)
    setRand(Math.random)
  }

  useEffect(() => {
    window.addEventListener("keydown", handleReset)
    window.addEventListener("scroll", handleReset)
    window.addEventListener("click", handleReset)

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", handleReset)
      window.removeEventListener("scroll", handleReset)
      window.removeEventListener("click", handleReset)
    }
  }, [rand])

  return (
    <div>
      {/* Test Page */}
      {show ? "showing text" : "not showing text"}
      <br />
      {counting ? "counting" : "not counting"}
      <br />
      <Typography>{rand}</Typography>
      {/* <Button onClick={handleReset}>Stop counting</Button>*/}
      {/* <Button onClick={handleReset}>Start counting</Button> */}
    </div>
  )
}

const styles = theme => ({
  root: {},
})
export default withStyles(styles)(TestPage)
